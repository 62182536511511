<template>
    <v-content>
        <div id="app">
            <v-app id="inspire">
                <v-container>
                    <v-sheet
                    >
                        <v-slide-group
                                v-model="pendingSlider"
                                class="w-100 pendingSlider"
                                active-class="success"
                                show-arrows
                        >
                            <v-slide-item
                                    v-for="(pendingItem, index) in pendingList"
                                    :key="index"
                            >
                                <v-card
                                        class="ma-2"
                                        height="80"
                                        min-width="200"
                                        @click="showPendingUsers(pendingItem)"
                                >
                                    <v-container>
                                        <h5> {{pendingItem.divisionName}}</h5>
                                        <table style="width: 100%">
                                            <tr class="">
                                                <td style="width: 50%">
                                                    <span class="text--darken-4 orange--text">Pending</span>
                                                </td>
                                                <td style="width: 50%; text-align: right">
                                                    <span class="text--darken-4 orange--text">{{pendingItem.pending}}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width: 50%">
                                                    <span class="text--darken-4 green--text">Registered
                                                    </span>
                                                </td>
                                                <td style="width: 50%; text-align: right">
                                                    <span class="text--darken-4 green--text">{{pendingItem.approved}}
                                                    </span>
                                                </td>
                                            </tr>
                                        </table>

                                        <!--&lt;!&ndash;<v-row>
                                            <v-col cols="auto" class="blue pl-5">
                                                <strong>{{pendingItem.pending}}</strong>
                                            </v-col>

                                            <v-col
                                                    cols="auto"
                                                    class="text-center pl-3"
                                            > {{pendingItem.moduleName}}
                                            </v-col>&ndash;&gt;
                                        </v-row>-->
                                    </v-container>
                                </v-card>
                            </v-slide-item>
                        </v-slide-group>
                    </v-sheet>
                    <div class="page-flex-container">
                        <div class="flex-item py-3 chartSection">
                            <v-container class="chartContainer">
                                <line-chart :chartData="lineData" :chartOptions="lineOptions"
                                            v-if="lineChart"></line-chart>
                            </v-container>
                        </div>
                        <div class="flex-item py-3" id="top5Countries">
                            <h3>Arrival By Country</h3>
                            <v-divider></v-divider>
                            <div class="progress-container">
                                <div class="progress-item" v-for="(progress,index) in immigrationCountryData"
                                     :key="index">
                                    <div class="progress">
                                        <v-progress-linear
                                                v-model="progress.power"
                                                :color="top5color[index]"
                                                height="25"
                                                reactive
                                                class="ma-1"
                                                dark
                                        >
                                            <strong>{{ progress.arrival }} / {{totalArrival}}</strong>
                                        </v-progress-linear>
                                    </div>
                                    <div class="progress-country">
                                        {{ progress.country }}
                                    </div>
                                </div>
                            </div>
                            <v-divider></v-divider>
                            <div class="progress-container-2" id="rationSection">
                                <div class="progress-item-2">
                                    <!-- <div class="change">17%</div>-->
                                    <div class="progress-value">{{totalArrival}}</div>
                                    <div class="progress-title">Total Arrival</div>
                                </div>
                                <div class="progress-item-2">
                                    <!--   <div class="change">27%</div>-->
                                    <div class="progress-value">{{this.summaryData.totalDeparture }}</div>
                                    <div class="progress-title">Total Departure</div>
                                </div>
                                <div class="progress-item-2">
                                    <div class="change">{{immigrationRatioData.increasedArrivalPercentage}} % ()
                                        <span class="group pa-2">
                                    <v-icon x-small> arrow-bottom-bold </v-icon></span></div>
                                    <div class="progress-value">
                                        {{immigrationRatioData.increasedArrivalRatio}}
                                    </div>
                                    <div class="progress-title">Arrival Ration Per Month</div>
                                </div>
                                <div class="progress-item-2">
                                    <div class="change">{{immigrationRatioData.increaseDeparturePercentage}}
                                        %
                                    </div>
                                    <div class="progress-value">
                                        {{immigrationRatioData.increaseDepartureRatio}}
                                    </div>
                                    <div class="progress-title">Departure Ratio Per Month</div>
                                </div>
                            </div>
                        </div>
                        <div class="flex-item py-3" id="provinceSection">
                                <v-row>
                                    <v-col cols="12" sm="3">
                                        <v-card class="" dark color="primary"
                                                v-for="(province, index) in provinces" :key="index"
                                                @click="$router.push({
                                                name:'DivisionList',
                                                params:{
                                                  provinceID: province.provinceID
                                                }
                                              })"
                                        >
                                                <!--   <router-link :to="'/division/'+province.provinceID">-->
                                                <v-card-subtitle dark class="">{{province.provinceName}}</v-card-subtitle>
                                                <v-card-title dark class="text-left">
                                                    Total
                                                    Division:{{province.totalDivision}}
                                                </v-card-title>
                                        </v-card>
                                    </v-col>
                                    <!--<v-col cols="12" sm="3">
                                        <v-card dark color="teal" class="">
                                            <v-card-subtitle class="divisionRegistered">Total Registered Us
                                            </v-card-subtitle>
                                            <v-card-title class="registereddivisionnumber">{{ summaryuserdata.totaluser }}
                                            </v-card-title>
                                        </v-card >
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-card class="divisions yellow ">
                                            <v-card-subtitle class="divisionRegistered">Total Active Users
                                            </v-card-subtitle>
                                            <v-card-title class="registeredDivisionNumber">{{ summaryUserData.activeUser }}
                                            </v-card-title>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-card dark class="divisions green ">
                                            <v-card-subtitle class="divisionRegistered">Total Registered Modules</v-card-subtitle>
                                            <v-card-title class="registeredDivisionNumber">{{this.pendingList.length}}
                                            </v-card-title>
                                        </v-card>
                                    </v-col>-->
                                </v-row>
                        </div>
                        <div class="flex-item py-3" id="contactSection">
                            <h3 class="contactPerson">Contact Person</h3>
                            <v-divider></v-divider>
                            <div class="contactDetailsGrid">
                                <div class="gridItem">
                                    <h4>Name</h4>
                                    <h5>Moctca Admin</h5>
                                </div>
                                <div class="gridItem">
                                    <h4>Phone</h4>
                                    <h5>01-4211669</h5>
                                    <h5>01-4211669</h5>
                                </div>
                            </div>

                            <v-dialog v-model="dialog.download" max-width="1000px">
                                <download
                                        v-if="dialog.download"
                                        @formResponse="onResponse"
                                />
                            </v-dialog>

                            <v-dialog v-model="dialog.upload" max-width="1200px">
                                <upload
                                        v-if="dialog.upload"
                                        @formResponse="onResponse"
                                />
                            </v-dialog>

                            <v-divider dark></v-divider>
                            <v-row>
                                <v-col>
                                    <div class="contactDetailsGrid">
                                        <div class="gridItem">
                                            <v-btn large color="purple
                                     darken-4" width="1000px"
                                                   dark
                                                   @click="downloadModalPopup"
                                            >{{$t('download_manual')}}
                                            </v-btn>
                                        </div>

                                    </div>
                                </v-col>
                                <v-col>
                                    <div class="contactDetailsGrid">
                                        <div class="gridItem">
                                            <v-btn large color="purple
                                     darken-4" width="100%"
                                                   dark
                                                   @click="uploadModalPopup"
                                            >{{$t('upload_manual')}}
                                            </v-btn>
                                        </div>

                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                        <div class="flex-item py-3" id="pieChartSection">
                            <v-container class="chartContainer">
                                <pie-chart :data="chartData" :options="chartOptions" v-if="donutChart"
                                ></pie-chart>
                            </v-container>
                        </div>

                    </div>
                </v-container>
            </v-app>
        </div>
        <v-snackbar
                v-model="snackbar.snackbar"
                :timeout="snackbar.timeout"
                top
        >
            {{ snackbar.message }}
            <v-btn
                    color=" error "
                    text
                    @click="snackbar.snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
        <v-dialog
                v-model="snackbar.dialogueForData"
                transition="dialog-bottom-transition"
                fullscreen
                persistent
                hide-overlay
        >
            <pendingUserList @dialogueClose="dialogueClose"
                             v-if="snackbar.pendingUserList"
                             :isSuperAdmin="true"
                             :pendingURL="snackbar.pendingURL"
                             :divisionName="snackbar.divisionName"
            >
            </pendingUserList>
        </v-dialog>
    </v-content>
</template>
<script>
    import axios from "axios";
    import LineChart from '@/components/LineChart'
    import PieChart from '@/components/PieChart'
    import pendingUserList from "../../components/PendingUserList"
    import download from "./modal/download";
    import upload from "./modal/upload";
    export default {
        name: "ProvinceDashboard",
        data() {
            return {
                dialog:{
                  download:false,
                  upload:false
                },
                snackbar: {
                    snackbar: false,
                    timeout: 5000,
                    dialogueForData: false,
                    pendingUserList: false,
                    pendingURL: '',
                    divisionName: '',
                },
                manual: '',
                immigrationCountryData: [],
                immigrationRatioData: {},
                totalArrival: null,
                totalDeparture: null,
                lineChart: false,
                donutChart: false,
                summaryUserData: {},
                summaryData: [],
                arrival: [],
                departure: [],
                chartOptions: {
                    hoverBorderWidth: 20,
                    cutoutPercentage: 30,
                    responsive: true,
                    maintainAspectRatio: false,

                    legend: {
                        position: 'right'
                    }
                },
                chartData: {
                    hoverBackgroundColor: "red",
                    hoverBorderWidth: 10,
                    labels: [],
                    datasets: [],
                },
                lineData: {
                    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    datasets: []
                },
                lineOptions: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            },
                            gridLines: {
                                display: false
                            }
                        }],
                        xAxes: [{
                            gridLines: {
                                display: false
                            }
                        }]
                    },
                    legend: {
                        display: true
                    },
                    responsive: true,
                    backgroundColor: '#000',
                    maintainAspectRatio: false
                },
                branchSummary: [],
                groupedBranchData: [],
                top5Countries: [
                    {value: 3000, total: 7000, country: 'India', power: 45},
                    {value: 2000, total: 7000, country: 'China', power: 25},
                    {value: 1000, total: 7000, country: 'Pakistan', power: 20,},
                    {value: 700, total: 7000, country: 'Malaysia', power: 7,},
                    {value: 300, total: 7000, country: 'Japan', power: 3},
                ],
                top5color: [
                    'purple darken-4',
                    'teal darken-4',
                    'orange darken-4 ',
                    'brown darken-3',
                    'brown darken-3',
                    'light-blue darken-3',
                ],
                pendingSlider: null,
                provinces: [],
                items: [
                    {
                        text: "Province",
                        disabled: true,
                        href: ""
                    }
                ],
                pendingList: [],
            };
        },
        mounted() {
        },
        created() {
            this.getBranchSummaryData()
            this.getImmigrationDataMonthly()
            this.getProvince();
            this.getTop5ArrivalDeartureCountry();
            this.getSummaryUserData();
            this.getImmigrationRatio();
        },
        methods: {
            onResponse(callBackResponse){
                if(callBackResponse.popup){
                    this.snackbar.snackbar = true
                    this.snackbar.message = callBackResponse.message
                }
                this.dialog.upload = false
                this.dialog.download = false
                console.log("response")
            },
            dialogueClose() {
                this.snackbar.dialogueForData = false
                this.snackbar.pendingUserList = false
            },
            uploadModalPopup(){
                this.dialog.upload = true
            },
            manualUpload() {
                var form = new FormData();
                form.append("Manual", this.manual[0]);
                console.log(this.manual)
                if (this.manual) {
                    axios.post("Upload/Upload", form).then(data => {
                        console.log('data', data)
                    })
                        .catch(e => {
                            console.log('error', e)
                        })
                }

            },
            downloadModalPopup(){
                this.dialog.download = true
            },
            async onDownload() {
                await axios({
                    url: 'Upload/Download',
                    method: 'GET',
                    responseType: 'blob'
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'manual.pdf'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            },
            getRandomColor() {
                return [
                    "#0023b8", "#3b8c2a", "#986b53", "#f50422", "#983f7a", "#ea24a3",
                    "#79352c", "#521250", "#c79ed2", "#d6dd92", "#e33e52", "#b2be57", "#fa06ec",
                    "#1bb699", "#6b2e5f", "#64820f", "#1c271", "#21538e", "#89d534", "#d36647",
                    "#7fb411", "#0023b8", "#3b8c2a", "#986b53", "#f50422", "#983f7a", "#ea24a3",
                    "#79352c", "#521250", "#c79ed2", "#d6dd92", "#e33e52", "#b2be57", "#fa06ec",
                    "#1bb699", "#6b2e5f", "#64820f", "#1c271", "#9cb64a", "#996c48", "#9ab9b7",
                    "#06e052", "#e3a481", "#0eb621", "#fc458e", "#b2db15", "#aa226d", "#792ed8",
                    "#73872a", "#520d3a", "#cefcb8", "#a5b3d9", "#7d1d85", "#c4fd57", "#f1ae16",
                    "#8fe22a", "#ef6e3c", "#243eeb", "#1dc18", "#dd93fd", "#3f8473", "#e7dbce",
                    "#421f79", "#7a3d93", "#635f6d", "#93f2d7", "#9b5c2a", "#15b9ee", "#0f5997",
                    "#409188", "#911e20", "#1350ce", "#10e5b1", "#fff4d7", "#cb2582", "#ce00be",
                    "#32d5d6", "#17232", "#608572", "#c79bc2", "#00f87c", "#77772a", "#6995ba",
                    "#fc6b57", "#f07815", "#8fd883", "#060e27", "#96e591", "#21d52e", "#d00043",
                    "#b47162", "#1ec227", "#4f0f6f", "#1d1d58", "#947002", "#bde052", "#e08c56",
                    "#28fcfd", "#bb09b", "#36486a", "#d02e29", "#1ae6db", "#3e464c", "#a84a8f",
                    "#911e7e", "#3f16d9", "#0f525f", "#ac7c0a", "#b4c086", "#c9d730", "#30cc49",
                    "#3d6751", "#fb4c03", "#640fc1", "#62c03e", "#d3493a", "#88aa0b", "#406df9",
                    "#615af0", "#4be47", "#2a3434", "#4a543f", "#79bca0", "#a8b8d4", "#00efd4",
                    "#7ad236", "#7260d8", "#1deaa7", "#06f43a", "#823c59", "#e3d94c", "#dc1c06",
                    "#f53b2a", "#b46238", "#2dfff6", "#a82b89", "#1a8011", "#436a9f", "#1a806a",
                    "#4cf09d", "#c188a2", "#67eb4b", "#b308d3", "#fc7e41", "#af3101", "#ff065",
                    "#71b1f4", "#a2f8a5", "#e23dd0", "#d3486d", "#00f7f9", "#474893", "#3cec35",
                    "#1c65cb", "#5d1d0c", "#2d7d2a", "#ff3420", "#5cdd87", "#a259a4", "#e4ac44",
                    "#1bede6", "#8798a4", "#d7790f", "#b2c24f", "#de73c2", "#d70a9c", "#25b67",
                    "#88e9b8", "#c2b0e2", "#86e98f", "#ae90e2", "#1a806b", "#436a9e", "#0ec0ff",
                    "#f812b3", "#b17fc9", "#8d6c2f", "#d3277a", "#2ca1ae", "#9685eb", "#8a96c6",
                    "#dba2e6", "#76fc1b", "#608fa4", "#20f6ba", "#07d7f6", "#dce77a", "#77ecca"];
            },
            getChartData() {
                let dataset = {
                    label: "Registered Users",
                    backgroundColor: [],
                    data: []
                }
                let labels = []
                this.pendingList.forEach(data => {
                    dataset.data.push(data.approved)
                    labels.push(data.divisionName)
                    dataset.backgroundColor = this.getRandomColor
                })
                /*  for (var i in  this.pendingList) {

                  }*/
                this.chartData.datasets.push(dataset)
                this.chartData.labels = labels
                this.donutChart = true
            },
            async getSummaryUserData() {
                try {
                    const {data} = await axios.get("User/GetActiveInActiveUserCountAsync")
                    this.summaryUserData = data
                    this.summaryUserData.totalUser = this.summaryUserData.activeUser + this.summaryUserData.inActiveUser
                } catch (e) {
                    console.log(e)
                }
            },
            async getImmigrationRatio() {
                try {
                     const {data} = await axios.get("Immigration/GetArrivalDeparturePreviousMonthSummary")
                   /* const {data} = {
                        data: {
                            increasedArrivalPercentage: 10,
                            increasedArrivalRatio: 1,
                            increaseDeparturePercentage: 20,
                            increaseDepartureRatio: 5,
                        }
                    }*/
                    this.immigrationRatioData = data;
                    if (this.immigrationRatioData.increasedArrivalPercentage > 0) {
                        this.immigrationRatioData.increasedArrivalPercentage.toFixed(2);
                    }
                    if (this.immigrationRatioData.increasedArrivalRatio > 0) {
                        this.immigrationRatioData.increasedArrivalRatio.toFixed(2);
                    }
                    if (this.immigrationRatioData.increaseDeparturePercentage > 0) {
                        this.immigrationRatioData.increaseDeparturePercentage.toFixed(2);
                    }
                    if (this.immigrationRatioData.increaseDepartureRatio > 0) {
                        this.immigrationRatioData.increaseDepartureRatio.toFixed(2);
                    }
                    console.log('this.immigrationRatioData', this.immigrationRatioData)

                } catch (e) {
                    console.log(e)
                }
            },
            async getTop5ArrivalDeartureCountry() {
                try {

                     const {data} = await axios.get("Immigration/GetArrivalDepartureByCountry")
                    this.immigrationCountryData = data.list
                    this.totalArrival = data.totalArrival
                    console.log('data', data)
                    if(this.immigrationCountryData.length){
                        this.immigrationCountryData.forEach(data => {
                            data.power = (data.arrival / this.totalArrival * 100).toFixed(2)
                        })
                    }
                    /*this.immigrationCountryData = [
                        {arrival: 14, country: 'India', power: 50},
                        {arrival: 10, country: 'Japan', power: 20},
                        {arrival: 6, country: 'China', power: 10},
                        {arrival: 8, country: 'Malaysia', power: 15},
                        {arrival: 2, country: 'Sri lanka', power: 5},
                    ]*/
                } catch (e) {
                    console.log(e)
                }
            },
            async getImmigrationDataMonthly() {
                try {
                    const summaryData = await axios.get("Immigration/GetArrivalDepartureByMonths")
                    this.summaryData = summaryData.data
                    this.getLineData()
                } catch (e) {
                    console.log(e)
                }
            },
            getLineData() {
                this.arrival = this.summaryData.graph.map(data => data.arrival)
                this.departure = this.summaryData.graph.map(data => data.departure)
                console.log(
                    [1, 2, 3, 4].reduce((a, b) => a + b, 0)
                )
                const arrivalData = {
                    label: 'Monthly Arrival Report',
                    backgroundColor: '#4DB6AC',
                    pointBackgroundColor: 'white',
                    borderWidth: 1,
                    pointBorderColor: '#4DB6AC',
                    data: this.arrival
                }
                const departureData = {
                    label: 'Monthly Departure Report',
                    backgroundColor: '#E57373',
                    pointBackgroundColor: 'white',
                    borderWidth: 1,
                    pointBorderColor: '#E57373',
                    data: this.departure
                }
                this.lineData.datasets.push(
                    arrivalData, departureData
                )
                this.lineChart = true;
            },
            async getBranchSummaryData() {
                try {
                    const branchSummary = await axios.get("Branch/GetBranchSummaryCountAsync")
                    this.branchSummary = branchSummary.data
                    this.groupedBranchData = this.groupArrayOfObjects(this.branchSummary, 'divisionName')
                    this.getTotalPendingData()
                    this.getChartData()
                } catch (e) {
                    console.log(e)
                }
            },
            async getProvince() {
                try {
                    const response = await axios.get("Province/GetProvinceListByMinistry");
                    this.provinces = response.data;
                } catch (e) {
                    if (e.response.data.State == "AccessTokenExpired") {
                        this.apiResponse(e);
                        this.loadItems();
                    }
                }
            },
            getTotalPendingData() {
                let division = []
                for (var key in this.groupedBranchData) {
                    let pending = 0;
                    let approved = 0;
                    let divisionName = '';
                    let divisionID = 0;
                    if (!this.groupedBranchData.hasOwnProperty(key)) continue;
                    division = this.groupedBranchData[key];
                    division.forEach(eachDivision => {
                        pending += eachDivision.pending
                        approved += eachDivision.approved
                        divisionName = eachDivision.divisionName
                        divisionID = eachDivision.divisionID
                    })
                    this.pendingList.push({
                        division,
                        pending,
                        approved,
                        divisionName,
                        divisionID
                    })
                }
                this.pendingList.sort((a, b) => (a.pending < b.pending) ? 1 : -1)
            },
            groupArrayOfObjects(list, key) {
                return list.reduce(function (rv, x) {
                    (rv[x[key]] = rv[x[key]] || []).push(x);
                    return rv;
                }, {});
            },
            showPendingUsers({divisionName}) {
                console.log(divisionName)
                this.snackbar.dialogueForData = true
                this.snackbar.pendingUserList = true
                this.snackbar.divisionName = divisionName
            }
        },
        components: {
            LineChart,
            PieChart,
            pendingUserList,
            download,
            upload
        }
    };
</script>
<style lang="scss" scoped>
    $blue: #265b93;
    a{ text-decoration: none}
    .pendingSlider {
        table {
            color: white;
            font-size: 12px;
            font-weight: 800;
        }
    }

    .uploadGrid {
        display: grid;
        grid-template-columns: 1fr auto;
    }

    #contactSection {
        padding: 1rem;
        /* background-color: #5E35B1;*/
        /* color: #eeeeee;*/

        .contactDetailsGrid {
            box-shadow: 0 3px 5px 0 #eeeeee73;

            .gridItem {
                padding: 1rem;
            }
        }
    }

    ::v-deep .chartContainer {
        flex-grow: 1;
        min-height: 0;
        width: 100%;

        ::v-deep > div {
            position: relative;
            width: 100%;
        }
    }

    .w-100 {
        width: 100%;
    }

    #rationSection {
        font-size: 12px;
    }

    .viewCard {
        transition: transform 1s;

        &:hover {
            -ms-transform: scale(1.1); /* IE 9 */
            -webkit-transform: scale(1.1); /* Safari 3-8 */
            transform: scale(1.1);
        }
    }

    .v-slide-group {
        ::v-deep.v-slide-group__next, ::v-deep.v-slide-group__prev {
            position: absolute;
            z-index: 3;
        }

        ::v-deep.v-slide-group__next {
            top: 42%;
            right: 0
        }

        ::v-deep.v-slide-group__prev {
            top: 42%;
            left: 0
        }
    }

    .chartSection, #top5Countries {
        background-color: #eeeeee65;
    }

    .page-flex-container {
        /* display: flex;
         flex-direction: row;
         flex-wrap: wrap;*/
        justify-content: center;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
        @media (min-width: 960px) {
            grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
        }
        @media (min-width: 1200px) {
            grid-template-columns: 1fr 1fr
        }
        grid-gap: 4px;

        #provinceSection {
            grid-column: 1/-1;
        }
    }

    /*
        .flex-item {
            flex-basis: 440px;
            flex-grow: 1;
            @media (min-width: 1200px) {
                flex-basis: 50%;

            }


        }*/

    #top5Countries {
        flex-basis: 360px;
        flex-grow: 1;

        .progress-item {
            display: grid;
            grid-template-columns: 3fr 1fr;
            grid-gap: 5px;
        }
    }

    .progress-container-2 {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        .progress-item-2 {
            flex-basis: 140px;
            @media (min-width: 1200px) {
                flex-basis: 43%;
            }
            flex-grow: 1;
            margin: 1px;
            padding: 2rem 1rem;
            text-align: center;
            box-shadow: 0 3px 5px 0 #88888823;
            background: linear-gradient(to top right, #33ccff 0%, #ff99cc 100%);
            font-weight: 600;
            display: grid;
            align-items: center;
        }

        .provinceList {
            flex-basis: 260px;
            flex-grow: 1;
            margin: 1px;
            padding: 1rem;
            text-align: center;
            box-shadow: 0 3px 5px 0 #88888823;
            font-weight: 600;
            color: white !important;
            background-color: #1E88E5 !important;
            display: grid;
            align-items: center;
        }
    }

    #provinceSection {
        flex-basis: 100%;
        color: white;
    }

    #registeredDataSection {
        flex-basis: 300px;
        flex-grow: 1;
        display: grid;
        color: white;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

        .divisionHolder {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin: 1px;

            > div {
                margin: 2px;
                flex: 1;
                background-color: #5E35B1;
                display: grid;
                align-items: center;
                text-align: center;
                padding: 0.6rem;
            }
        }
    }

    .green {
        background-color: #00695C;
    }

    .red {
        background-color: #B71C1C;
    }

    .yellow {
        background-color: #F57F17;
        color: black;
    }

    .blue {
        background-color: #0277BD;
    }

    .v-application a {
        color: white;
    }
</style>